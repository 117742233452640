// roboflow.js

let roboflowModel = null;

// Initialize Roboflow model
export function initializeRoboflow(publishableKey, callback) {
  if (window.roboflow) {
    window.roboflow.auth({
      publishable_key: publishableKey,
    })
    .load({
      model: 'anchored-reality',
      version: 1,
    })
    .then((loadedModel) => {
      roboflowModel = loadedModel;
      console.log('Roboflow model loaded successfully:', loadedModel);
      if (callback) {
        callback(null, loadedModel);
      }
    })
    .catch((error) => {
      console.error('Error loading Roboflow model:', error);
      if (callback) {
        callback(error, null);
      }
    });
  } else {
    const error = 'Roboflow SDK is not available';
    console.error(error);
    if (callback) {
      callback(error, null);
    }
  }
}

// Run object detection
export function detectObjects(videoElement, callback) {
  if (!roboflowModel) {
    const error = 'Roboflow model not initialized';
    console.error(error);
    if (callback) {
      callback(error, []);
    }
    return;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  roboflowModel.detect(canvas)
    .then((predictions) => {
      // if (predictions && predictions.length > 0) {
      //   console.log("Predictions detected:", predictions);
      // }
      if (callback) {
        callback(null, predictions);
      }
    })
    .catch((err) => {
      console.error('Error during object detection:', err);
      if (callback) {
        callback(err, []);
      }
    });
}