import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import CardItem from '../CardItem';

function AboutUs(){
return (
    <div className='cards__container'>
      <h1 className='about-us'>ABOUT US</h1>
      <div className='cards__wrapper'>
        <ul className='cards__items'>
          <CardItem
            src='images/AndrewKruszkaHeadshot_2.jpg'
            text='Andrew Kruszka'
            subText= {"Hi, I'm Andrew Kruszka. I led the computer vision and object detection development for our project. My role involved developing our anchor design and training a custom object detection model using Roboflow. I was also tasked with integrating a page into our website that would use the model to detect objects in a user's environment." + 
            "\n\n I'm a senior at the University of Missouri studying Computer Science and I'm passionate about using technology to solve real-world problems. Outside of school, I am an active member of the Mizou Colleiate Entrepreneurs' Organization where I work with other studetns to support and start small businesses."}
            //path='/services'
            linkedinUrl="https://www.linkedin.com/in/andrew-kruszka" // Replace with Andrew's LinkedIn URL
          />
          <CardItem
            src='images/JoeHeadshot.jpg'
            text='Joe Kelley'
            subText= {"Hi, I'm Joe Kelley. I handle the database management and infrastructure. I was responsible for setting up the database and ensuring that the data was stored and retrieved correctly. I also worked on the backend of the website, ensuring that the data was displayed correctly. I also worked heavily on the owner's view making sure that the data was retrieved and shown to the owner in the proper fashion." +
              "\n\n I graduate Fall 2024 from Mizzou with a bachelor's in Computer Science with the goal to go into the field of software, data analysis, or security."
            }
            //path='/services'
            linkedinUrl="https://www.linkedin.com/in/joseph-kelley-56831822b/" // Replace with Joe's LinkedIn URL
          />
          <CardItem 
            src='images/ChrisWangHeadshot.jpg'
            text='Chris Wang'
            subText= {"Hello, my name is Chris Wang, and I’m leading the front-end design and development for our project, Anchored Reality. My role encompasses creating a cohesive and engaging brand identity, including designing the logo, selecting a color palette, and developing a user interface. By combining aesthetics with usability, I aim to create a seamless experience that aligns with our brand values and promotes our product with a professional look." +
            "\n\n Aside from school, I’m involved with Marching Mizzou as the Media Coordinator! Prior to this role, I was a member and section leader of the mellophone section. I also spent many semesters playing French Horn in the Horn Studio and various concert ensembles."}
            //path='/services'
            linkedinUrl="https://www.linkedin.com/in/chris-wang-284270224/" // Replace with Chris's LinkedIn URL
          />
        </ul>
      </div>
    </div>
  );
}  
  
export default AboutUs;