import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Products from './components/pages/Products';
//import SignUp from './components/pages/SignUp';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import CameraView from './components/pages/CameraView';
import Camera3DView from './components/pages/Camera3DView'
import threeScene from './components/pages/threeScene';
import Testing from './components/pages/Testing';
import PrivateRoute from './components/PrivateRoute';
import AboutUs from './components/pages/AboutUs';
import { cameraViewMatrix } from 'three/webgpu';
import NotFound from './components/pages/NotFound';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({email: '', name:''})

  return (
    <>
      {/* Wrap the whole app inside the GoogleOAuthProvider and Router */}
      <GoogleOAuthProvider clientId="1055985582760-1nkblnco6v4p3f3rucdo678ft00jkf7g.apps.googleusercontent.com">
        <Router>
          {/* Render Navbar inside the Router so it can use Link */}
          <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} userInfo={userInfo} setUserInfo={setUserInfo}/>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/user_view/:anchorid' component={CameraView} />
            <Route path='/test3d' component={threeScene} />
            
            {/* Use PrivateRoute for Owner View */}
            <PrivateRoute
              path='/owner_view'
              userInfo = {userInfo}
              component={Products}
              isLoggedIn={isLoggedIn}
            />

            <Route path='/about-us' component={AboutUs} /> 
            {/*<Route path='/Testing' component={Testing} /> */}
            <Route path="*" component={NotFound} />
            <GoogleLogin/>
          </Switch>
        </Router>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;


