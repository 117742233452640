import React from "react";
import '../Forms.css';
import { useState } from 'react';
import { registerAnchor } from "../../api/db/dbApi";


function RegisterAnchor(props) {

    // inputs are used for function and error is used to set the return value
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState({status:true, msg:""});

    function handleChangeInputs(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
     }

     async function handleSubmit(event) {
        event.preventDefault();
        const update = await registerAnchor(props.email, inputs.id);
        // update[0] is false on error, therefore show our error message
        if (!update[0]){
            setError({status:update[0], msg:update[1]});
        } else {
            // may remove, but this closes the popup
            props.setTrigger(false)
        }
    }

    // when no error show our form, else show the error message
    return error.status ? (
        <form onSubmit={handleSubmit}>
            <p>Let's Register and Anchor!</p>
            <input type='text' name='id' placeholder='ID of Anchor' value={inputs.id || ""} onChange={handleChangeInputs}></input>
            <button className="submit-btn">Register</button>
        </form>
    ) : (
        <div>
            <p>{error.msg}</p>
            <button className="submit-btn" onClick={() => setError({status:true, msg:""})}>Try Again</button>
        </div>
    );
}

export default RegisterAnchor