import React, { useState, useEffect } from 'react';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
// import { Route, Redirect } from 'react-router-dom';
import './Navbar.css';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

function Navbar({ isLoggedIn, setIsLoggedIn , userInfo, setUserInfo}) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  // const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);

    return () => {
      window.removeEventListener('resize', showButton);
    };
  }, []);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>

        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src='/images/AnchoredRealityLogoFinal_White.png' alt='' style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
          Anchored Reality
        </Link>

          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
            </li>
            {/*<li className='nav-item'>
              <Link
                to='/user_view'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                User View
              </Link>
            </li>
              */}
            {/* Conditionally render Owner View only if user is signed in */}
            {isLoggedIn && (
              <li className='nav-item'>
                <Link
                  to='/owner_view'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Owner View
                </Link>
              </li>
            )}

            {/* Google Login button placed outside the nav menu */}
          <li className="nav-item">
            {
              isLoggedIn ?
                <p className="nav-links" onClick={() => {setIsLoggedIn(false); setUserInfo({email:'', name:''});}}>Logout</p>
              :
              <GoogleLogin
              onSuccess={(credentialResponse) => {
                googleLogout();
                let decoded = jwtDecode(credentialResponse.credential)
                setUserInfo({email:decoded.email, name:decoded.name})
                setIsLoggedIn(true); // Set logged-in state to true
              }}
              onError={() => {
                console.log('Login Failed');
                setUserInfo({email:'', name:''})
                setIsLoggedIn(false); // Set logged-in state to false
              }}
            />
            }
          </li>

        </ul>


        </div>
      </nav>
    </>
  );
}

export default Navbar;

