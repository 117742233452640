import React, { Suspense } from "react";
import '../Forms.css';
import { useState, useEffect } from 'react';
import { getAnchorList, linkAnchor, updateAnchor } from "../../api/db/dbApi";
import { deleteObject, getObject } from "../../api/aws/awsApi";
import Select from "react-select";
import Popup from '../Popup';

// props to expect
// email
// objectKey
function ObjectForm(props) {

    const [is3D, set3D] = useState(false);
    const [tbnl, setTbnl] = useState();

    // error is used to show error popup
    const [error, setError] = useState({status:true, msg:""});

    // file and image state used for loading and deleting images
    const [file, setFile] = useState();
    const [img, setImg] = useState("");

    // warningn is used for deltetion warning
    const [warning, setWarning] = useState(false);

    // options below are used for assignments
    const [selectedValue, setSelectedValue] = useState('');
    const [options, setOptions] = useState([]);
    const [activeAnchors, setActiveAnchors] = useState([]);

    // asyncly get all data needed
    useEffect(() => {
        async function getData(email, key) {
            const allAnchors = await getAnchorList(email);
            if (allAnchors[0]){
                allAnchors[1].forEach(obj => {
                    options.push({value: obj.anchorid, label: obj.anchorid});
                    if (obj.filename === key){
                        activeAnchors.push(obj.anchorid);
                        options.pop();
                    }
                });
            }

            const object_res = await getObject(email, key);

            if(object_res[0]){
                setFile(object_res[1])
                if(!["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(object_res[1].type)) {
                    const tbnl_res = await getObject(email, "tbnl_"+key+".png");
                    set3D(true)
                    setTbnl(tbnl_res[1])
                    setImg(URL.createObjectURL(tbnl_res[1]));
                } else {
                    setImg(URL.createObjectURL(object_res[1]))
                }
            } else {
                setError({status:object_res[0], msg:object_res[1]});
            }
        }
        getData(props.email, props.objectKey);
    }, [props])

    //  delet button also unlinks all linked anchors
    function deleteButtonClick(event){
        if(activeAnchors.length !== 0){
            activeAnchors.forEach(anchor => {
                updateAnchor(props.email, anchor, {filename:"", fileType:"", uploadDate:null})
                .then((res) => {
                    if(!res[0]) {
                        setError({status:res[0], msg:res[1]})
                    }
                })
            });
        }

        deleteObject(props.email, props.objectKey)
        .then((res) => {
            if(res[0]){
                if(is3D){
                    deleteObject(props.email, tbnl.name)
                    .then((res) => {
                        if(res[0]){
                            // successfully deleted
                            props.setTrigger(false);
                        } else {
                            setError({status:res[0], msg:res[1]})
                        }
                    })
                } else {
                    // successfully deleted
                    props.setTrigger(false);
                }
            } else {
                setError({status:res[0], msg:res[1]})
            }
        })

        
    }

    // you can assign to anchors from here
    function assignAnchors(){
        selectedValue.forEach(option => {
            linkAnchor(props.email, option.value, file)
            .then((res) => {
                if(!res[0]){
                    setError({status:res[0], msg:res[1]});
                    return;
                }
            })
        });

        props.setTrigger(false)
    }

    // when no error show our form, else show the error message
    return error.status ? (
        <div className="img-form-container">
            <p>{props.objectKey}</p>
            {is3D && <p>This is a 3D Object</p>}
            <div className="form-img">
                <img src={img} alt={props.objectKey}/>
            </div>
            <div className="form-right">
                <button className="submit-btn" onClick={() => {if(activeAnchors.length === 0){deleteButtonClick()} else {setWarning(true)}}}>Delete Object</button>
                <Popup trigger={warning} setTrigger={setWarning}>
                    <div>
                        <p>The object you are about to delete is attached to the follolwing anchor ids</p>
                        <p>{activeAnchors.map((anchor, index) => {
                            return <span key={index}>{anchor} </span> 
                        })}</p>
                        <p>Deleting this object will unassign those anchors. Continue?</p>
                        <button className="submit-btn" onClick={deleteButtonClick}>YES</button>
                        <button className="submit-btn" onClick={() => {setWarning(false)}}>No</button>
                    </div>
                </Popup>
                <p>Anchors to Assign to</p>
                <Select onChange={setSelectedValue} options={options} isMulti/>
                <button className="submit-btn" onClick={assignAnchors}>Assign to Anchor</button>
                <p>Assigned to Anchors:</p>
                <p>{activeAnchors.map((anchor, index) => {
                        return <span key={index}>{anchor} </span> 
                    })}
                </p>
            </div>
        </div>
    ) : (
        <div>
            <p>{error.msg}</p>
            <button className="submit-btn" onClick={() => setError({status:true, msg:""})}>Try Again</button>
        </div>
    );
}

export default ObjectForm