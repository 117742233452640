import React, { lazy, Suspense, useEffect } from 'react';
import '../../App.css';
import { useState } from 'react';
import Popup from '../Popup';
import RegisterAnchor from '../forms/RegisterAnchor';
import AnchorForm from '../forms/AnchorForm';
import UploadForm from '../forms/UploadForm.js';
import ObjectForm from '../forms/ObjectForm.js';
import { bucketExists } from '../../api/helpers.js';
import { createBucket } from '../../api/aws/awsApi.js';

const AnchorGallery = lazy(() => import('../AnchorGallery.js'));
const ObjectGallery = lazy(() => import('../ObjectGallery.js'));


export default function Products(props) {

  const email = props.userInfo.email;
  const name = props.userInfo.name;
  const [hasPermission, setHasPermission] = useState(true);

  // create a bucket id user doesn't have one
  useEffect(() => {
    document.title = "AR · Owner"
    bucketExists(email).then((exists) => {
      if(!exists){
        // removing autocreating buckets whijle in development
        //createBucket(email);
        setHasPermission(false)
      }
    })
  })

  const [registerPopup, setRegisterPopup] = useState(false);
  const [anchorPopup, setAnchorPopup] = useState(false);
  const [currentAnchor, setCurrentAnchor] = useState("");

  const [uploadPopup, setUploadPopup] = useState(false);
  const [objectPopup, setObjectPopup] = useState(false);
  const [currentObject, setCurrentObject] = useState("");

  if (hasPermission) {
    return (
        <div className = 'container'>
          <h1 className='products' style={{ color: '#104688'}}>Welcome {name}!</h1>

          {/* Title and Upload Button */}
          <div className='header'>
            <h1 className='title'>Library</h1>
            <button className='upload-button' onClick={() => setUploadPopup(true)}>Upload</button>
            <Popup trigger={uploadPopup} setTrigger={setUploadPopup}>
              <UploadForm email={email} setTrigger={setUploadPopup}/>
            </Popup>
          </div>

          {/* Photo Gallery for Library */}
          <div className='gallery-container'>
            <Suspense fallback={<div>LOADING...</div>}>
              <ObjectGallery email={email} setObjectPopup={setObjectPopup} setCurrentObject={setCurrentObject}/>
            </ Suspense>
            <Popup trigger={objectPopup} setTrigger={setObjectPopup}>
              <ObjectForm email={email} objectKey={currentObject} setTrigger={setObjectPopup}/>
            </Popup>
          </div>

          {/* Anchors and Register Button */}
          <div className='header'>
            <h1 className='title'>Anchors</h1>
            <button className='upload-button' onClick={() => setRegisterPopup(true)}>Register</button>
            <Popup trigger={registerPopup} setTrigger={setRegisterPopup}>
              <RegisterAnchor email={email} setTrigger={setRegisterPopup}/>
            </Popup>
          </div>

          {/* Photo Gallery for Anchors */}
          <div className='gallery-container'>
            <Suspense fallback={<div>LOADING...</div>}>
              <AnchorGallery email={email} setAnchorPopup={setAnchorPopup} setCurrentAnchor={setCurrentAnchor}/>
            </Suspense>
          </div>
            <Popup trigger={anchorPopup} setTrigger={setAnchorPopup}>
              <AnchorForm email={email} anchorId={currentAnchor} setTrigger={setAnchorPopup}/>
            </Popup>

        </div>
    );
  } else {
    return (
      <div className='whitelist'>Thank you for visiting anchored reality. We are currently whitelisting access to the website
        while we are still in a development phase.
        Please contact us at <a href='mailto:ar.anchored.reality@gmail.com'>ar.anchored.reality@gmail.com</a> to get access.
      </div>
    );
  }
}


