import { getOwnerBucketName, bucketExists, apiEndpoint} from "../helpers"


const awsEndpoint = `${apiEndpoint}/aws`


/**
 * Creates a bucket
 * @param {String} owner email address of owner
 * @returns array (bool:changed, "error message")
 */
async function createBucket(owner) {
    // check if bucket exists
    let err = await bucketExists(owner)
    if (err){
        return [false, "bucket exists for owner"];
    }

    // create a hash for bucket and create bucket
    let bucketName = getOwnerBucketName(owner);
    let response = await fetch(`${awsEndpoint}/create/${bucketName}`, {method: 'POST'});

    if(response.ok){
        return [true, "created"];
    } else {
        return [false, "error in creating bucket"];
    }
}

/**
 * Upload file to owner bucket
 * @param {String} owner email address of owner
 * @param {File} file upload File
 * @returns array (bool:changed, "error message")
 */
async function uploadObject(owner, file) {
    let err = await bucketExists(owner)
    if (!err){
        return [false, "bucket does not exist"];
    }

    let fileName = file.name;

    // convert to array tp convert to json object
    let blob = await file.arrayBuffer();
    blob = new Uint8Array(blob);

    let bucketName = getOwnerBucketName(owner);
    let response = await fetch(`${awsEndpoint}/upload/${bucketName}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "text/plain",
                filename: fileName,
                uploadtype: file.type
            },
            body: blob
        });
    
    if(response.ok){
        return [true, await response.json()];
    } else {
        return [false, await response.json()];
    }
}

/**
 * Delete object from owner bucket
 * @param {String} owner email address of owner
 * @param {String} filename object name to delete 
 * @returns array (bool:changed, "error message")
 */
async function deleteObject(owner, filename) {
    let err = await bucketExists(owner)
    if (!err){
        return [false, "bucket does not exist"];
    }

    let bucketName = getOwnerBucketName(owner);
    let response = await fetch(`${awsEndpoint}/delete/${bucketName}/${filename}`, {
        method: 'DELETE'
    });

    if(response.ok){
        return [true, `deleted ${filename}`];
    } else {
        return [false, await response.json()];
    }
}

/**
 * Get list of objects in owner bucket
 * @param {String} owner owner email address
 * @returns array (bool, "error message")
 */
async function getOwnerObjects(owner) {
    let err = await bucketExists(owner)
    if (!err){
        return [false, "bucket does not exist"];
    }

    let bucketName = getOwnerBucketName(owner);
    let response = await fetch(`${awsEndpoint}/list/${bucketName}`);

    if(response.ok){
        return [true, await response.json()];
    } else {
        return [false, await response.json()];
    }
}

/**
 * Get object
 * @param {String} owner owner email address
 * @param {String} filename object name to get
 * @returns array (bool, "error message" or {file object})
 */
async function getObject(owner, filename) {
    let err = await bucketExists(owner)
    if (!err){
        return [false, "bucket does not exist"];
    }

    let bucketName = getOwnerBucketName(owner);
    try {
        let response = await fetch(`${awsEndpoint}/${bucketName}/${filename}`);
    
        let file = new File([await response.blob()], filename, {
            type: response.headers.get("content-type"),
        });
        return [true, file];
    } catch (error) {
        return [false, error]
    }
}


export {uploadObject, createBucket, deleteObject, getOwnerObjects, getObject}