import { createBucket, deleteObject, getObject, getOwnerObjects, uploadObject } from '../../api/aws/awsApi';
import { getAnchor, getAnchorList, getAnchorObject, linkAnchor, registerAnchor, updateAnchor} from '../../api/db/dbApi';
import { useState } from 'react';


function Testing() {
    const [inputs, setInputs] = useState({});
    const [options, setOptions] = useState({});

    const [file, setFile] = useState();

    const [imgState, setImg] = useState();

    function handleUploadChange(event){
        setFile(event.target.files[0]);
    }

    async function handleUpload(event) {
        event.preventDefault();
        console.log(file);

        const upload = await uploadObject("joseph@thekelleys.org", file);
        console.log(upload);

        const link = await linkAnchor("joseph@thekelleys.org", "1", file);
        console.log(link)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const update = await updateAnchor(inputs.email, inputs.id, options);
        console.log(update);
    }

    function handleChangeInputs(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
     }

     function handleChangeOptions(event) {
        const name = event.target.name;
        const value = event.target.value;
        setOptions(values => ({...values, [name]: value}));
     }

     async function handleGetImage(event) {
        getObject("joseph@thekelleys.org", "University_of_Missouri_logo.svg")
        .then((result) => 
            {
                if (result[0]){
                    let url = URL.createObjectURL(result[1])
                    setImg(url); 
                    console.log(result);
                }
            }
        );
     }

     async function handleGetImageAnchor(event) {
        getAnchorObject("1")
        .then((result) => 
            {
                if (result[0]){
                    let url = URL.createObjectURL(result[1])
                    setImg(url); 
                    console.log(result);
                } else {
                    console.log(result);
                }
            }
        );
     }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <input type='text' name='email' placeholder='email' value={inputs.email || ""} onChange={handleChangeInputs}></input>
            <input type='text' name='id' placeholder='Anchor id' value={inputs.id || ""} onChange={handleChangeInputs}></input>
            <input type='text' name='anchorName' placeholder='Anchor Name' value={options.anchorName || ""} onChange={handleChangeOptions}></input>
            <input type='text' name='ownerAlias' placeholder='Owner Alias' value={options.ownerAlias || ""} onChange={handleChangeOptions}></input>
            <input type='text' name='desc' placeholder='anchor description' value={options.desc || ""} onChange={handleChangeOptions}></input>

            <button type='submit'>Submit</button>
        </form>
        <button onClick={async () => registerAnchor("ar.anchored.reality@gmail.com", "2").then((result) => console.log(result))}>Test Register</button>
        <button onClick={async () => getAnchor("joseph@thekelleys.org", "2").then((result) => console.log(result))}>Test Get</button>
        <button onClick={async () => getAnchorList("joseph@thekelleys.org").then((result) => console.log(result))}>Test Get List</button>
        <button onClick={async () => updateAnchor("joseph@thekelleys.org", "1", {anchorName: "Joe's_Anchor",ownerAlias:"joe", desc:"Description from react"}).then((result) => console.log(result))}>Test Update</button>
        
        <form onSubmit={handleUpload}>
            <p>Upload</p>
            <input type='file' onChange={handleUploadChange}></input>
            <button type='submit'>Upload</button>
        </form>

        <button onClick={async () => createBucket("joseph@thekelleys.org").then((result) => console.log(result))}>Test Create Bucket</button>
        <button onClick={async () => getOwnerObjects("joseph@thekelleys.org").then((result) => console.log(result))}>Test List Bucket</button>
        <button onClick={async () => deleteObject("joseph@thekelleys.org", "University_of_Missouri_logo.svg").then((result) => console.log(result))}>Test Delete</button>
        
        <br/>
        <button onClick={handleGetImage}>Test Get Object</button>
        <button onClick={handleGetImageAnchor}>Test Get Object Anchor 1</button>
        
        <img src={imgState}></img>

        </>
        
    )
}

export default Testing;