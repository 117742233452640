import * as THREE from 'three';
import { useRef, useEffect, useState } from 'react';
import { initializeRoboflow, detectObjects } from '../../api/roboflow/roboflow.js';

const PyramidScene = () => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [anchorDetected, setAnchorDetected] = useState(false);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const pyramidRef = useRef(null);
  const highlightBoxRef = useRef(null); // Ref for the bounding box highlight
  const scene = useRef(new THREE.Scene());

  useEffect(() => {
    // Set up Three.js camera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5; // Start with a default position
    cameraRef.current = camera;

    // Set up the renderer and attach it to the container div
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Resize handler to keep canvas full screen
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Create the pyramid geometry (tetrahedron) and add it to the scene
    const pyramidGeometry = new THREE.TetrahedronGeometry(2);
    const pyramidMaterial = new THREE.MeshStandardMaterial({ color: 0x0000FF });
    const pyramid = new THREE.Mesh(pyramidGeometry, pyramidMaterial);
    pyramid.castShadow = true;
    pyramid.visible = false; // Start hidden, only show when anchor is detected
    scene.current.add(pyramid);
    pyramidRef.current = pyramid;

    // Create a semi-transparent bounding box highlight
    const highlightBoxGeometry = new THREE.PlaneGeometry(1, 1); // Placeholder dimensions
    const highlightBoxMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.3, transparent: true });
    const highlightBox = new THREE.Mesh(highlightBoxGeometry, highlightBoxMaterial);
    highlightBox.visible = false; // Start hidden
    scene.current.add(highlightBox);
    highlightBoxRef.current = highlightBox;

    // Add a light source
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(-5, 5, 5);
    light.castShadow = true;
    scene.current.add(light);

    // Animation loop to render the scene
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene.current, cameraRef.current);
    };
    animate();

    // Initialize the Roboflow model
    initializeRoboflow("rf_cmx7eGjwNDbEUF9B8UITkBIGORH2", (err, model) => {
      if (!err) {
        setModelLoaded(true);
        console.log("Roboflow model loaded successfully.");
      } else {
        console.error("Error loading Roboflow model:", err);
      }
    });

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (rendererRef.current) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const updatePyramidPosition = (x, y, width, height) => {
    if (!pyramidRef.current || !cameraRef.current || !highlightBoxRef.current) return;

    // Calculate the center of the bounding box in screen coordinates
    const centerX = x + width / 2;
    const centerY = y + height / 2;

    // Convert the bounding box center to normalized device coordinates (-1 to 1)
    const ndcX = (centerX / videoRef.current.videoWidth) * 2 - 1;
    const ndcY = -((centerY / videoRef.current.videoHeight) * 2 - 1); // Flip Y-axis

    // Create a 3D position vector from the 2D normalized coordinates
    const vector = new THREE.Vector3(ndcX, ndcY, 0.5); // Z = 0.5 for midway between near and far planes
    vector.unproject(cameraRef.current); // Convert to 3D world space

    // Calculate direction from the camera to the target
    const direction = vector.sub(cameraRef.current.position).normalize();

    // Set a fixed distance from the camera for the pyramid
    const distance = 5;
    const newPosition = cameraRef.current.position.clone().add(direction.multiplyScalar(distance));

    // Update pyramid position without rotating it
    pyramidRef.current.position.copy(newPosition);
    pyramidRef.current.rotation.set(0, 0, 0); // Ensure rotation stays fixed

    // Update bounding box highlight to match the detected marker's position and size
    highlightBoxRef.current.position.copy(newPosition);
    highlightBoxRef.current.scale.set(width / videoRef.current.videoWidth * 2, height / videoRef.current.videoHeight * 2, 1);
  };

  useEffect(() => {
    if (modelLoaded) {
      // Start the camera stream and set up the video texture
      const startCameraStream = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
          videoRef.current.srcObject = stream;

          // Run detection only after the video metadata is loaded
          videoRef.current.addEventListener('loadedmetadata', () => {
            videoRef.current.play();

            // Run detection in a loop
            const runDetection = () => {
              if (videoRef.current && videoRef.current.videoWidth > 0 && videoRef.current.videoHeight > 0) {
                detectObjects(videoRef.current, (err, predictions) => {
                  if (err) {
                    console.error("Detection error:", err);
                    setAnchorDetected(false);
                    pyramidRef.current.visible = false;
                    highlightBoxRef.current.visible = false;
                  } else if (predictions && predictions.length > 0) {
                    setAnchorDetected(true);
                    pyramidRef.current.visible = true;
                    highlightBoxRef.current.visible = true;

                    const { x, y, width, height } = predictions[0]; // Assume one anchor detection for simplicity

                    // Update the pyramid's position and bounding box highlight based on detected anchor
                    updatePyramidPosition(x, y, width, height);

                    console.log("Anchor detected, moving pyramid to match the anchor position.");
                  } else {
                    setAnchorDetected(false);
                    pyramidRef.current.visible = false;
                    highlightBoxRef.current.visible = false;
                    console.log("No anchor detected, keeping pyramid hidden.");
                  }
                });
              }

              // Request next frame
              requestAnimationFrame(runDetection);
            };

            runDetection(); // Start detection loop after video metadata is loaded
          });
        } catch (error) {
          console.error("Error accessing the camera:", error);
        }
      };

      startCameraStream();
    }
  }, [modelLoaded]);

  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <video
        ref={videoRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1 // Place the video behind the Three.js canvas
        }}
        autoPlay
        playsInline
        muted
      />
    </div>
  );
};

export default PyramidScene;
