import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isLoggedIn, userInfo, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} userInfo={userInfo}/>
      ) : (
        <Redirect to='/' /> // Redirect to home page if not logged in
      )
    }
  />
);

export default PrivateRoute;
