// Helper functions to be used by multiple handlers
import { SHA1, enc } from "crypto-js";
import { getOwnerObjects } from "./aws/awsApi";

export var apiEndpoint = "https://0zbl3urp74.execute-api.us-east-1.amazonaws.com/api"

/**
 * Get the haashed bucket name of a user
 * @param {String} owner email address of owner
 * @returns {String} Hash to be used as bucketname
 */
function getOwnerBucketName(owner){
    return SHA1(owner).toString(enc.Hex);
}

/**
 * Checks if owner has a bucket
 * @param {String} owner email address of owner
 * @returns {Boolean} if owner exists or not
 */
async function bucketExists(owner){
    let bucketName = getOwnerBucketName(owner);

    try {
        let response = await fetch(`${apiEndpoint}/aws/list/${bucketName}`).then((res) => {return res.json()});
        
        if(response.Code){
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.log(error)
        return false;
    }
}

/**
 * Checks if owner owns file
 * @param {String} owner email address of owner
 * @param {String} filename name of object
 * @returns {Boolean} if object exists
 */
async function checkFile(owner, filename) {
    let list = await getOwnerObjects(owner);

    if(!list[0]){
        return false;
    }

    let match = false;
    list[1].forEach(obj => {
        if(filename === obj.Key){
            match = true;
        }
    });

    return match;
}

export {getOwnerBucketName, bucketExists, checkFile}