import React, { useState } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleTrailerClick = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className='hero-container'>
      <img src="/images/AnchoredRealityLogoFinal_White.png" alt="Logo" className='hero-image' />
      <h1>ANCHORED REALITY</h1>
      <p>What are you waiting for?</p>
      <div className='hero-btns'>
        <Button
          buttonStyle='btn--outline'
          buttonSize='btn--medium'
          isLink={true}
          linkPath='/about-us'
        >
          ABOUT US
        </Button>

        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={handleTrailerClick}
        >
          WATCH DEMO <i className='far fa-play-circle' />
        </Button>
      </div>
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>Close</button>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-SxTHCk58Uk?si=rDyjPI2TfnNVeBTw&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeroSection;
