import React from "react";
import '../Forms.css';
import { useState, useEffect } from 'react';
import { getAnchor, linkAnchor, updateAnchor } from "../../api/db/dbApi";
import { getObject, getOwnerObjects } from "../../api/aws/awsApi";
import {Select} from "react-select";

// props to expect
// email
// anchorId
function AnchorForm(props) {

    // inputs are used for function and error is used to set the return value
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState({status:true, msg:""});
    const [img, setImg] = useState("");

    // file used for linking
    const [currentFile, setCurrentFile] = useState('');
    
    // options used for linking
    const [selectedValue, setSelectedValue] = useState('');
    const [options, setOptions] = useState([{ value: '', label: 'unassigned' }]);

    // asyncly get all neccessary data
    useEffect(() => {
        async function getData(email, anchorid) {

            const allObjects = await getOwnerObjects(email);
            if (allObjects[0]){
                allObjects[1].forEach(obj => {
                    if(!(obj.Key.substring(0, 5) === "tbnl_")){
                        options.push({value: obj.Key, label: obj.Key});
                    }
                });
            }

            const anchorData = await getAnchor(email, anchorid);
            setInputs({
                "anchorName": anchorData[1]["anchorName"],
                "ownerAlias": anchorData[1]["ownerAlias"],
                "desc": anchorData[1]["desc"]
            });


            if (anchorData[1]["filename"] === ""){
                setImg("/images/EmptyAnchor.png");
            } else {
                // get the thumbnail image, set it for 3d objects
                let tbnl = anchorData[1]["filename"]
                if(!["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(anchorData[1]["fileType"])){
                    tbnl = "tbnl_"+tbnl+".png"
                }
                const anchorImg_res = await getObject(email, tbnl);
                if (anchorImg_res[0]){
                    // setOptions([{value: anchorData[1]["filename"], label: anchorData[1]["filename"]}]);
                    setSelectedValue(anchorData[1]["filename"])
                    setCurrentFile(anchorData[1]["filename"])
                    setImg(URL.createObjectURL(anchorImg_res[1]));
                } else {
                    setError({status:anchorImg_res[0], msg:anchorImg_res[1]});
                }
            }
        }
        getData(props.email, props.anchorId);
    }, [props])


    // change update inputs
    function handleChangeInputs(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
     }

     // cahnge everything needed on submit
     async function handleSubmit(event) {
        event.preventDefault();
        const update = await updateAnchor(props.email, props.anchorId, inputs);
        // update[0] is false on error, therefore show our error message
        if (!update[0]){
            setError({status:update[0], msg:update[1]});
            return;
        }

        if (currentFile !== selectedValue){
            if(selectedValue === ''){
                const unlink = await updateAnchor(props.email, props.anchorId, {filename:"", fileType:"", uploadDate:null})
                if (!unlink[0]){
                    setError({status:unlink[0], msg:unlink[1]});
                    return;
                }
            } else {
                const fileToLink = await getObject(props.email, selectedValue);
                if (!fileToLink[0]){
                    setError({status:fileToLink[0], msg:fileToLink[1]});
                    return;
                }

                const link = await linkAnchor(props.email, props.anchorId, fileToLink[1]);
                if (!link[0]){
                    setError({status:link[0], msg:link[1]});
                    return;
                }
            }
        }

        props.setTrigger(false)
    }

    // when no error show our form, else show the error message
    return error.status ? (
        <div className="img-form-container">
            <p>Anchor ID: {props.anchorId}</p>
            <div className="form-img">
                <img src={img} alt="anchor"/>
                {/**Need to add a dropdown here for reassigning or a popup? */}
            </div>
            <div className="form-right">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="anchorName">Anchor's Name</label>
                    <input type='text' id="anchorName" name='anchorName' placeholder='Anchor Name' value={inputs.anchorName || ""} onChange={handleChangeInputs}></input>
                    <label htmlFor="ownerAlias">Owner Alias</label>
                    <input type='text' id= "ownerAlias" name='ownerAlias' placeholder='Owner Alias' value={inputs.ownerAlias || ""} onChange={handleChangeInputs}></input>
                    <label htmlFor="desc">Anchor Description</label>
                    <input type='text' id="desc"  name='desc' placeholder='Anchor description' value={inputs.desc || ""} onChange={handleChangeInputs}></input>
                    <button className="submit-btn">Update</button>
                </form>
            </div>
            <select value={selectedValue} onChange={(event) => {setSelectedValue(event.target.value);}} >
                    {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                    ))}
            </select>
        </div>
    ) : (
        <div>
            <p>{error.msg}</p>
            <button className="submit-btn" onClick={() => setError({status:true, msg:""})}>Try Again</button>
        </div>
    );
}

export default AnchorForm