import React from 'react';
import '../../App.css';

const NotFound = () => {
  return (
    <div>
      <h1 className = "whitelist">404 - Page Not Found</h1>
    </div>
  );
};

export default NotFound;