import React, { useRef, useEffect, useState } from 'react';
import { initializeRoboflow, detectObjects } from '../../api/roboflow/roboflow.js';
import { useParams } from 'react-router-dom'
import { getAnchor, getAnchorObject } from '../../api/db/dbApi';

const CameraView = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [error, setError] = useState(null);
  const [floatingImageStyle, setFloatingImageStyle] = useState({ display: 'none' });

  const {anchorid} = useParams()
  const [anchorObj, setAnchorObj] = useState("");


  useEffect(() => {

    // Grab anchor object based on anchorid
    getAnchorObject(anchorid).then((res) => {
      if (res[0]){
        setAnchorObj(URL.createObjectURL(res[1]))
      }
    })

    // Load and initialize Roboflow SDK
    const loadRoboflowSDK = () => {
      const script = document.createElement('script');
      script.src = "https://cdn.roboflow.com/0.2.26/roboflow.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        initializeRoboflow("rf_cmx7eGjwNDbEUF9B8UITkBIGORH2", (err, loadedModel) => {
          if (err) {
            console.error("Error loading Roboflow model:", err);
            setError("Error loading model");
          }
        });
      };

      script.onerror = () => {
        console.error("Failed to load the Roboflow SDK");
        setError("Failed to load SDK");
      };
    };

    loadRoboflowSDK();
  }, []);

  useEffect(() => {
    // Start the camera stream
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { ideal: 'environment' } },
        });
        setHasPermission(true);
        videoRef.current.srcObject = stream;

        videoRef.current.addEventListener('loadedmetadata', () => {
          if (canvasRef.current && videoRef.current.videoWidth && videoRef.current.videoHeight) {
            canvasRef.current.width = videoRef.current.videoWidth;
            canvasRef.current.height = videoRef.current.videoHeight;
          }
        });
      } catch (err) {
        console.error('Error getting camera stream:', err);
        setError('Permission denied or camera not available');
      }
    };
    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    // Run object detection if model is ready and permission granted
    if (hasPermission) {
      const video = videoRef.current;
      const canvas = canvasRef.current;

      const detectAndDisplayObjects = () => {
        if (video.videoWidth && video.videoHeight) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          detectObjects(video, (err, predictions) => {
            if (err) {
              console.error("Detection error:", err);
              return;
            }

            if (predictions && predictions.length > 0) {
              const prediction = predictions[0];

              if (prediction && prediction.bbox) {
                const { x, y, width, height } = prediction.bbox;
                const scaleX = window.innerWidth / video.videoWidth;
                const scaleY = window.innerHeight / video.videoHeight;

                setFloatingImageStyle({
                  display: 'block',
                  left: x * scaleX,
                  top: y * scaleY,
                  width: width * scaleX,
                  height: height * scaleY,
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                });
              }
            } else {
              setFloatingImageStyle({ display: 'none' });
            }

            requestAnimationFrame(detectAndDisplayObjects);
          });
        } else {
          console.warn("Video dimensions not ready for detection");
          requestAnimationFrame(detectAndDisplayObjects);
        }
      };

      detectAndDisplayObjects();
    }
  }, [hasPermission]);

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <img
        src={anchorObj !== "" ? anchorObj : "/images/MainLogo.png"}
        alt="Floating"
        style={floatingImageStyle}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default CameraView;
