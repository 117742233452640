import React, { Suspense, useRef } from "react";
import '../Forms.css';
import { useState } from 'react';
import { uploadObject } from "../../api/aws/awsApi";
import {Canvas} from "@react-three/fiber";
import {OrbitControls, useGLTF} from "@react-three/drei"
import { ErrorBoundary } from "react-error-boundary";

function Model(props) {
    const {scene} = useGLTF(props.url)
  
    return (
      <primitive object={scene} />
    );
  }


function UploadForm(props) {

    // inputs are used for function and error is used to set the return value
    const [file, setFile] = useState();
    const [error, setError] = useState({status:true, msg:""});
    const [img, setImg] = useState("");
    const [obj, setObj] = useState("");
    const [disabled, setDisabled] = useState(true);

    const canvasRef = useRef();

     function handleUploadChange(event){
        setFile(event.target.files[0]);

        if(event.target.files[0].size > 50000000) {
            setError({status:false, msg:"File too large."});
            return;
        }

        if(event.target.files && ["image/jpeg", "image/png", "image/svg+xml", "image/jpg"].includes(event.target.files[0].type)){
            setObj("")
            setImg(URL.createObjectURL(event.target.files[0]));
            setDisabled(false)
        } else if (event.target.files) {
            setImg("")
            setObj(URL.createObjectURL(event.target.files[0]))
            setDisabled(false)
        }
    }

    async function handleUpload(event) {
        event.preventDefault();
        if (file){
            if(obj !== ""){
                canvasRef.current.toBlob((blob) => {
                    const tbnl = new File([blob], "tbnl_"+file.name+".png", {type: "image/png"})
                    uploadObject(props.email, tbnl).then((res) => {
                        if(!res[0]){
                            setError({status:res[0], msg:res[1]});
                            return false;
                        }
                    });
                });
            }
            const upload = await uploadObject(props.email, file);

            if(!upload[0]) {
                setError({status:upload[0], msg:upload[1]});
                return;
            }
            props.setTrigger(false);
        } else {
            setError({status:false, msg:"No file selected or bad input."});
            return;
        }
    }

    // when no error show our form, else show the error message
    return error.status ? (
        <div>
            {
                img !== "" && (<img src={img} alt="uploaded file" width={100} height={100}></img>)
            }
            {
                obj !== "" && (
                    
                    <Suspense fallback={<p>Loading obj</p>}>
                        <ErrorBoundary fallback={<div>Error in rendering object</div>} onError={() => {setDisabled(true)}}>
                            <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }} >
                                <ambientLight intensity={5}/>
                                <OrbitControls />
                                <Model url={obj}/>
                            </Canvas>
                        </ErrorBoundary>
                    </Suspense>
                    
                )
            }
            <form onSubmit={handleUpload}>
                <p>Upload</p>
                <input type='file' accept=".jpg,.jpeg,.png,.svg,.gltf,.glb" onChange={handleUploadChange}></input>
                <button type='submit' disabled={disabled}>Upload</button>
            </form>
        </div>
        
    ) : (
        <div>
            <p>{error.msg}</p>
            <button className="submit-btn" onClick={() => setError({status:true, msg:""})}>Try Again</button>
        </div>
    );
}

export default UploadForm