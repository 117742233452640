import { getObject } from "../aws/awsApi";
import { getOwnerBucketName, apiEndpoint, checkFile} from "../helpers"


const dbEndpoint = `${apiEndpoint}/db`

/**
 * Check anchor owner
 * @param {String} owner email address of owner
 * @param {String} anchorid id of anchor
 * @returns array (bool:if matches, error code): 0 none, 1 owner doesn't match, 
 * 2 no owner exists, 3 no anchor
 */
async function checkOwner(owner, anchorid) {
    let ownerHash = getOwnerBucketName(owner)
    // check if anchor is unclaimed
    const response = await fetch(`${dbEndpoint}/anchor/${anchorid}`);
    if(response.ok){
        var anchorOwner = await response.json();
        // if anchorOwner is not empty, anchor is already registered
        if(anchorOwner.owner === ownerHash) {
            return[true, 0];
        } else if (anchorOwner.owner === ""){
            return [false, 2];
        } else if (anchorOwner.owner !== ownerHash) {
            return [false, 1]
        }
    } else {
        // if response was not ok, anchor couldn't be found
        return [false, 3]
        }
}

/**
 * Get anchor info
 * @param {String} owner email address of owner
 * @param {String} anchorid id of anchor
 * @returns array (bool:logged in, anchor object/error)
 */
async function getAnchor(owner, anchorid) {
    const ownerCheck = await checkOwner(owner, anchorid)

    // check if owner is actual owner of anchor
    if(!ownerCheck[0]){
        return [false, "Anchor is not registered to owner"];
    }

    const response = await fetch(`${dbEndpoint}/anchor/${anchorid}`);
    let data = await response.json()
    if(response.ok){
        return [true, data]
    } else {
        return [false, data]
    }
}

/**
 * Get list of all anchors assigned to owner
 * @param {String} owner email address of owner
 * @returns array (bool:error, anchor objects/error)
 */
async function getAnchorList(owner) {
    let ownerHash = getOwnerBucketName(owner)

    const response = await fetch(`${dbEndpoint}/anchor/list/${ownerHash}`);
    let data = await response.json()
    if(response.ok){
        return [true, data]
    } else {
        return [false, data]
    }
}

/**
 * Register New Anchor to owner
 * @param {String} owner email address of owner
 * @param {String} anchorid id of anchor
 * @returns array (bool:logged in, "error message")
 */
async function registerAnchor(owner, anchorid) {
    let ownerHash = getOwnerBucketName(owner)

    const ownerCheck = await checkOwner(owner, anchorid)
    // check owner and we want an empty owner to continue
    if(ownerCheck[1] !== 2){
        return [false, "Anchor is either already registered or does not exist"];
    }

    // When registering an amchor we only assign the owner
    const update = await fetch(`${dbEndpoint}/anchor/${anchorid}`, {
        method: "PUT",
        headers: {
           "Content-Type": "application/json",
        },
        body: JSON.stringify(
            {
                owner: ownerHash
            }
        ),
     });

    // update returns based on response
    if(update.ok){
        return [true, "Success"];
    } else {
       return [false, "Error in assinging anchor"];
    }

}

/**
 * Update an owners anchor
 * @param {String} owner email address of owner
 * @param {String} anchorid id of anchor
 * @param {Object} options options to be changed to anchor, MUST BE IN FORM OF ANCHOR MODEL OR ERROR
 * @returns array (bool:changed, "error message")
 */
async function updateAnchor(owner, anchorid, options) {
    const ownerCheck = await checkOwner(owner, anchorid)

    // check if owner is actual owner of anchor
    if(!ownerCheck[0]){
        return [false, "Anchor is not registered to owner"];
    }

    // When registering an amchor we only assign the owner
    const update = await fetch(`${dbEndpoint}/anchor/${anchorid}`, {
        method: "PUT",
        headers: {
           "Content-Type": "application/json",
        },
        body: JSON.stringify(
            options
        ),
    });
    
    let response = await update.json()
    // update returns based on response acknowledment from MongoDB
    if(response.acknowledged){
        return [true, response];
    } else {
       return [false, "Error no changes"];
    }
}


/**
 * Update an owners anchor
 * @param {String} owner email address of owner
 * @param {String} anchorid id of anchor
 * @param {File} object object file to link to
 * @returns array (bool:changed, "error message")
 */
async function linkAnchor(owner, anchorid, object) {
    const ownerCheck = await checkOwner(owner, anchorid)

    // check if owner is actual owner of anchor
    if(!ownerCheck[0]){
        return [false, "Anchor is not registered to owner"];
    }

    // check if owner owns file with the same name
    const fileCheck = await checkFile(owner, object.name);
    if (!fileCheck) {
        return [false, "Owner hasn't uploaded file"];
    }

    let body = {
        filename: object.name,
        fileType: object.type,
        uploadDate: new Date()
    }

    // When registering an amchor we only assign the owner
    const update = await fetch(`${dbEndpoint}/anchor/${anchorid}`, {
        method: "PUT",
        headers: {
           "Content-Type": "application/json",
        },
        body: JSON.stringify(
            body
        ),
    });
    
    let response = await update.json()
    // update returns based on response acknowledment from MongoDB
    if(response.acknowledged){
        return [true, response];
    } else {
       return [false, "Error no changes"];
    }
}

/**
 * Get object from anchor
 * @param {String} anchorid
 * @returns array (bool, "error message" or {file object}, 0 (on error), {anchor data object})
 */
async function getAnchorObject(anchorid) {
    try{
        const response = await fetch(`${dbEndpoint}/anchor/${anchorid}`);
        var anchorData = await response.json();

        if (anchorData.error){
            throw new Error(response.error)
        }

        if (anchorData.owner === ""){
            throw new Error("Anchor is not registered")
        } else if (anchorData.filename === "") {
            throw new Error("Anchor does not have an uploaded object")
        }
    } catch (error) {
        return [false, error, -1]
    }

    let bucketName = anchorData.owner;
    let filename = anchorData.filename;

    try {
        let response = await fetch(`${apiEndpoint}/aws/${bucketName}/${filename}`);
    
        let file = new File([await response.blob()], filename, {
            type: response.headers.get("content-type"),
        });
        return [true, file, anchorData];
    } catch (error) {
        return [false, error, 0]
    }
}

export {registerAnchor, updateAnchor, getAnchor, getAnchorList, linkAnchor, getAnchorObject}