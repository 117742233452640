import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt={props.text}
              src={props.src}
            />
          </figure>
        <div className='cards__item__info'>
            <div className='text-and-logo'>
              <h5 className='cards__item__text'>{props.text}</h5>
              <a href={props.linkedinUrl} target="_blank" rel="noopener noreferrer">
              <img
                src="/images/LinkedIn.png"  // Replace with correct path to LinkedIn logo
                alt="LinkedIn Logo"
                className="linkedin-logo"
              />
            </a>
            </div>
          <p className='cards__item__subtext' style={{ whiteSpace: 'pre-line' }}>
          {props.subText}
          </p>
        </div>
      </li>
    </>
  );
}

export default CardItem;

